import classnames from 'classnames';
import * as React from 'react';
import { VisibleFieldsSections } from '../../components/SearchBar/models';
import { Product, VisibleField } from '../../models/search';
import { toCurrency } from '../../utils/format';
import Image from '../Image/Image';
import { ProductListItemProps } from './models';
import styles from './productListItem.scss';
import htmlReactParser from 'html-react-parser';

type ProductValue = string | number | boolean | React.ReactNode;

const Label = ({
  label,
  value,
  className,
}: {
  label: string;
  value: ProductValue;
  className: string;
}) =>
  value || label ? (
    <p className={className}>
      {label ? <span>{label}</span> : null}
      {value}
    </p>
  ) : null;

const ProductData = ({
  item,
  Link,
  linkProps,
  value,
  onClick,
}: {
  item: VisibleField<Product>;
  Link: React.ElementType;
  linkProps: React.DetailedHTMLProps<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  >;
  value: ProductValue;
  onClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}) => {
  switch (item.prop) {
    case 'url':
      return (
        <Link {...linkProps} className={styles.link} onClick={onClick}>
          View to see price
        </Link>
      );
    case 'title':
      return (
        <Link
          {...linkProps}
          className={classnames(
            styles.text,
            `${PROP_CLASS_NAME}${item.prop}`,
            item.className
          )}
          onClick={onClick}
        >
          {value}
        </Link>
      );

    case 'has_options':
      return value ? (
        <div className={styles.optionAvailableBtnContainer}>
          <Link
            {...linkProps}
            className={classnames(
              styles.optionsAvailableBtn,
              `${PROP_CLASS_NAME}${item.prop}`
            )}
          >
            Options available
          </Link>
        </div>
      ) : null;

    case 'fitment_applicability':
      return value ? (
        <span
          className={classnames(
            styles.text,
            `${PROP_CLASS_NAME}${item.prop}`,
            value === 'Fitment-Specific' ? 'fitmentSpecific' : 'universal'
          )}
        >
          {value}
        </span>
      ) : null;

    default:
      return (
        <Label
          className={classnames(
            styles.text,
            `${PROP_CLASS_NAME}${item.prop}`,
            item.className
          )}
          label={item.label}
          value={value}
        />
      );
  }
};

const PROP_CLASS_NAME = 'Sui-ProductListItem--';
export const DEFAULT_VISIBLE_FIELDS: ProductListItemProps['visibleFields'] = [
  { position: 'center', prop: 'brand_name' },
  { position: 'center', prop: 'title' },
  { position: 'center', prop: 'tag' },
  { label: 'SKU: ', position: 'center', prop: 'stockid' },
  { label: 'PART #: ', position: 'center', prop: 'dealerid' },
  { position: 'right', prop: 'availability' },
  { position: 'right', prop: 'sale' },
  { position: 'right', prop: 'price' },
  { position: 'right', prop: 'remarks' },
  { position: 'right', prop: 'availability_remarks' },
];

const ProductListItem = ({
  data,
  styled,
  visibleFields = DEFAULT_VISIBLE_FIELDS,
  className = '',
  orientation = 'horizontal',
  noImageUrl,
  linkEl = 'a',
  onItemClick,
}: ProductListItemProps) => {
  const Link = data.url ? linkEl : 'div';
  const linkProps = data.url ? { href: data.url } : {};
  const fieldsToReduce = Array.isArray(visibleFields)
    ? visibleFields
    : DEFAULT_VISIBLE_FIELDS;
  const fieldsInSections: VisibleFieldsSections = fieldsToReduce.reduce(
    (acc, item) => {
      if (data[item.prop] === undefined || data[item.prop] === null) {
        return acc;
      }
      switch (item.position) {
        case 'center':
          return { ...acc, center: [...acc.center, item] };
        case 'right':
          return { ...acc, right: [...acc.right, item] };
        default:
          return acc;
      }
    },
    { center: [], right: [] }
  );

  return (
    <section
      className={classnames(
        styles.root,
        {
          [styles.styledSearchResultItem]: styled,
          [styles.vertical]: orientation === 'vertical',
        },
        `${PROP_CLASS_NAME}root`,
        className
      )}
      role="listitem"
    >
      <Link
        className={classnames(styles.imageContainer, `${PROP_CLASS_NAME}image`)}
        onClick={(e) => onItemClick?.(e, data)}
        {...linkProps}
      >
        <Image
          alt="Search reult"
          className={styles.productImg}
          url={data.image_url}
          noImageUrl={noImageUrl}
        />
      </Link>
      <ul>
        {fieldsInSections.center.map((item) => (
          <li
            key={item.prop}
            className={`Sui-ProductListItem-Container--${item.prop}`}
            role="listitem"
          >
            <ProductData
              item={item}
              Link={Link}
              linkProps={linkProps}
              value={formatFieldValue(
                item.prop,
                data[item.prop],
                data.has_product_series
              )}
              onClick={(e) => onItemClick?.(e, data)}
            />
          </li>
        ))}
      </ul>
      <ul className={styles.right}>
        {fieldsInSections.right.map((item) => (
          <li
            key={item.prop}
            className={`Sui-ProductListItem-Container--${item.prop}`}
            role="listitem"
          >
            <ProductData
              item={item}
              Link={Link}
              linkProps={linkProps}
              value={formatFieldValue(
                item.prop,
                data[item.prop],
                data.has_product_series
              )}
              onClick={(e) => onItemClick?.(e, data)}
            />
          </li>
        ))}
      </ul>
    </section>
  );
};

export default ProductListItem;

function formatFieldValue(
  key: keyof Product,
  value: Product[keyof Product],
  hasProductSeries: boolean
) {
  switch (key) {
    case 'price':
      if (value) {
        const parsedValue = toCurrency(value as Product['price' | 'sale']);
        return hasProductSeries ? (
          <>
            <span
              className={classnames(
                styles.priceFromLabel,
                `Sui-ProductListItem-Container--${key}-label-from`
              )}
            >
              From:
            </span>{' '}
            {parsedValue}
          </>
        ) : (
          parsedValue
        );
      }
      return value;
    case 'sale':
      return value ? toCurrency(value as Product['price' | 'sale']) : value;
    case 'tag':
    case 'availability_remarks':
    case 'remarks':
      return htmlReactParser(
        value as Product['tag' | 'availability_remarks' | 'remarks']
      );
    default:
      return value;
  }
}
