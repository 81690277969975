import classnames from 'classnames';
import * as React from 'react';
import { FACET_PREFIX, SelectedFitmentValues } from '.';
import Modal from '../../common/Modal/Modal';
import { convertSelectedDataToArray } from '../../utils/fitmentUtils';
import {
  FitmentSelectorProps,
  FitmentLabelEntity,
  SelectedValues,
} from '../FitmentSelector/models';
import FitmentSelectorWrapper from '../FitmentSelectorWrapper';
import { FitmentSelectorWrapperProps } from '../FitmentSelectorWrapper/models';
import styles from './fitmentSelectorModal.module.scss';
import { SearchPageContext } from './SearchPageProvider';
import { updateUrl } from './utils';

interface FitmentSelectorModalProps {
  classes: { root: string };
  isModalOpen: boolean;
  disbaleAllHtmlEl: boolean;
  showModal: (value: boolean) => void;
  onSubmit?: (values: SelectedFitmentValues, changed: boolean) => void;
  groupId?: FitmentSelectorWrapperProps['groupId'];
  /**
   * Pipe delimited Fitment
   * Example: 2020|Chevrolet|Colorado
   */
  selectedFitment?: string;
}

const FitmentSelectorModal = ({
  classes,
  disbaleAllHtmlEl,
  isModalOpen,
  showModal,
  onSubmit,
  groupId,
  selectedFitment: initialSelectedFitment,
}: FitmentSelectorModalProps) => {
  const {
    clearFacetsOnSaveState,
    facetKeysState,
    selectedFacetState,
  } = React.useContext(SearchPageContext);
  const [facetKeys] = facetKeysState;
  const [, setSelectedFacets] = selectedFacetState;
  const [clearFacetsOnSave] = clearFacetsOnSaveState;

  const [fitmentLabels, setFitmentLabels] = React.useState<
    FitmentLabelEntity[]
  >([]);
  const [fitmentValues, setFitmentValues] = React.useState<
    FitmentSelectorProps['labelsData']
  >();
  const [
    selectedFitment,
    setSelectedFitment,
  ] = React.useState<SelectedValues>();
  const [fitmentKey, setFitmentKey] = React.useState('');
  const [isChanged, setIsChanged] = React.useState(false);

  React.useEffect(() => {
    if (fitmentLabels.length && initialSelectedFitment) {
      const selectedFitmentValues = initialSelectedFitment.split('|');
      const selectedFitmentParsed = fitmentLabels.reduce(
        (acc, label, index) => {
          return {
            ...acc,
            [label.name]: selectedFitmentValues[index],
          };
        },
        {}
      );

      setSelectedFitment(selectedFitmentParsed);
      setFitmentKey(JSON.stringify(selectedFitmentParsed));
    }
  }, [fitmentLabels, initialSelectedFitment]);

  React.useEffect(() => {
    setIsChanged(false);
  }, [isModalOpen]);

  if (!groupId) {
    return null;
  }

  return (
    <Modal
      className={classnames(classes.root, styles.modal, {
        disableAll: disbaleAllHtmlEl,
      })}
      isOpen={isModalOpen}
      onClose={() => showModal(false)}
      showCloseButton={true}
    >
      <div className={styles.header}>
        <h1 className="Pl-Fitment-modal--title">Select your fitment</h1>
      </div>

      <div className={styles.modalBody}>
        <FitmentSelectorWrapper
          /*
            Need to change the key when fitment labels
            or selected fitment changes to set initial values properly.
            This will re-mount the component
          */
          key={fitmentKey}
          onDataLoaded={(labels, _, data) => {
            if (labels && data) {
              setFitmentLabels(labels);
              setFitmentValues(data);
            }
          }}
          className={styles.firmentSelector}
          styled={false}
          orientation="vertical"
          searchButtonText="Save"
          onSubmit={(values) => {
            onSubmit?.(
              convertSelectedDataToArray(
                fitmentLabels,
                values,
                fitmentValues || {}
              ),
              isChanged
            );
            showModal(false);
            if (clearFacetsOnSave) {
              setSelectedFacets({});
              facetKeys.forEach((item) =>
                updateUrl<string>(`${FACET_PREFIX}${item}`, [])
              );
            }
          }}
          onClearCb={() => {
            // On clearing the fitments,
            // pre-selected facets should not be cleared
            if (clearFacetsOnSave) {
              setSelectedFacets({});
              facetKeys.forEach((item) =>
                updateUrl<string>(`${FACET_PREFIX}${item}`, [])
              );
            }
          }}
          onChange={(_, values) => {
            setIsChanged(true);
            if (!values) {
              onSubmit?.([], true);
            }
          }}
          groupId={groupId}
          selectedValues={selectedFitment}
        />
      </div>
    </Modal>
  );
};

export default FitmentSelectorModal;
