import classnames from 'classnames';
import * as React from 'react';
import { Orientation } from '../../models/generic';
import FunnelIcon from '../../static/icons/FunnelIcon';
import VerifiedIcon from '../../static/icons/VerifiedIcon';
import '../../styles/global.scss';
import Alert from '../Alert';
import BreadCrumb from '../BreadCrumb';
import FacetFilter from '../FacetFilter';
import { FacetFilterOnChange } from '../FacetFilter/models';
import { FitmentSelectorWrapperProps } from '../FitmentSelectorWrapper/models';
import LoadingIndicator from '../LoadingIndicator';
import { ProductListResponse } from '../ProductListWrapper/models';
import WsmProductListWrapper from '../WsmProductListWrapper';
import FacetFilterModal from './FacetFilterModal';
import FitmentSelectorModal from './FitmentSelectorModal';
import styles from './searchPage.module.scss';
import { SearchPageContext, SearchPageProvider } from './SearchPageProvider';
import {
  getInitialFitment,
  getProductQuery,
  getSearchParams,
  isFacetEqual,
  parseResponse,
  parseSelectedFacets,
  updateUrl,
} from './utils';

// Facet query string prefix to get facets from URL and prevent extra requests to load filtered products.
export const FACET_PREFIX = 'facet-';

export type SelectedFitmentValues = Array<{
  key: string;
  value: number | string;
}>;

export type SearchParams = {
  q: string;
  page: number;
  orientation: Orientation;
  sort: string | number;
  limit: number;
  fitment: string;
};

export type SelectedFacetsType = { [key: string]: number[] | string[] };

interface SearchPageProps {
  groupId?: FitmentSelectorWrapperProps['groupId'];
}

/*
  This component was created to be used in a non React application,
  for that reason we use URLSearchParams instead of React Router and
  the component is not optimized for an SPA. If you need a
  component optimized for SPA, please create one using sunahmmer-ui components.
*/
const InnerSearchPage = ({ groupId }: SearchPageProps) => {
  const queryString = new URLSearchParams(window.location.search);

  const [searchParams, setSearchParams] = React.useState<SearchParams>(
    getSearchParams(queryString)
  );
  const [productData, setProductData] = React.useState<ProductListResponse>();
  const {
    clearFacetsOnSaveState,
    selectedFacetState,
    facetKeysState,
  } = React.useContext(SearchPageContext);
  const [facetKeys, setFacetKeys] = facetKeysState;
  const [selectedFacets, setSelectedFacets] = selectedFacetState;
  const [, setClearFacetsOnSave] = clearFacetsOnSaveState;

  const [selectedFitmentStr, setSelectedFitmentStr] = React.useState(
    getInitialFitment(queryString)?.replace(/\|/g, ' ') || ''
  );
  const [selectedFitmentQuery, setSelectedFitmentQuery] = React.useState(
    getInitialFitment(queryString)
  );
  const [isFacetModalOpen, setIsFacetModalOpen] = React.useState(false);
  const [isFitmentModalOpen, setIsFitmentModalOpen] = React.useState(false);
  const [disbaleAllHtmlEl, setDisableAllHtmlEl] = React.useState(false);
  const facetCounter = React.useRef<number>(0);
  const collapseOnShowFacetModal = React.useRef(false);
  const [productsQuery, setProductsQuery] = React.useState(
    getProductQuery(selectedFacets, selectedFitmentQuery, groupId)
  );
  const allowUpdateProductQuery = React.useRef<boolean>(false);

  React.useEffect(() => {
    facetCounter.current = Object.keys(selectedFacets).reduce(
      (acc, item) => acc + selectedFacets[item].length,
      0
    );
  }, [selectedFacets]);

  React.useEffect(() => {
    // We don't want to update the product query object on first render becasue was already
    // set on the useState function, so we wait for a change on selectedFacets or selectedFitmentQuery
    if (allowUpdateProductQuery.current) {
      setProductsQuery(
        getProductQuery(selectedFacets, selectedFitmentQuery, groupId)
      );
    }
    allowUpdateProductQuery.current = true;
  }, [selectedFacets, selectedFitmentQuery]);

  React.useEffect(() => {
    if (!groupId) {
      // Clear fitment query when no groupId value is not provided
      updateUrl('fitment');
    }
  }, [groupId]);

  React.useEffect(() => {
    window.onpopstate = () => {
      setSearchParams(
        getSearchParams(new URLSearchParams(window.location.search))
      );
    };
  }, []);

  const updateFacets = (data: ProductListResponse) => {
    if (!data?.facets.length) {
      return;
    }
    const facetkeys = data?.facets.map((item) => item.name) || [];
    setFacetKeys(facetkeys);
    const newSelectedFecets = facetkeys.reduce((acc, facet) => {
      const values = queryString.getAll(`${FACET_PREFIX}${facet}`);
      if (values.length) {
        return {
          ...acc,
          [facet]: values,
        };
      }
      return acc;
    }, {});
    if (!isFacetEqual(selectedFacets, newSelectedFecets)) {
      setSelectedFacets(newSelectedFecets);
    }
  };

  const renderCounter = (renderZero = false) => {
    return facetCounter.current || renderZero ? (
      <span
        className={classnames(
          styles.facetCounter,
          'Pl-filter-result--button--counter'
        )}
      >
        {facetCounter.current}
      </span>
    ) : null;
  };

  const renderFacet = (isMobile = false) => {
    if (!productData?.facets.length) {
      return null;
    }
    const collapse = isMobile && collapseOnShowFacetModal.current;
    return (
      <div className={styles.facetContainer}>
        {groupId ? (
          <div
            className={classnames(styles.desktopFitmentButton, {
              [styles.hide]: selectedFitmentStr,
            })}
          >
            {renderFitmentButton()}
          </div>
        ) : null}
        {isMobile && disbaleAllHtmlEl ? (
          <LoadingIndicator type="linear" />
        ) : null}

        <FacetFilter
          className={classnames({ [styles.facetFilterDesktop]: !isMobile })}
          data={parseResponse(productData?.facets, collapse)}
          styled
          enableCollapse
          selectedValues={selectedFacets}
          onChange={(data) => {
            setDisableAllHtmlEl(true);
            collapseOnShowFacetModal.current = false;
            setSearchParams({ ...searchParams, page: 1 });
            const parsedFacets = parseSelectedFacets(
              data as FacetFilterOnChange
            );
            setSelectedFacets(parsedFacets);
            facetKeys.forEach((item) =>
              updateUrl<string>(
                `${FACET_PREFIX}${item}`,
                parsedFacets[item] || []
              )
            );
          }}
        />
      </div>
    );
  };

  const showFacetModal = (show: boolean) => {
    collapseOnShowFacetModal.current = show;
    setIsFacetModalOpen(show);
  };

  const renderFitmentButton = () =>
    !selectedFitmentStr ? (
      <button
        className={styles.fitmentButton}
        onClick={() => setIsFitmentModalOpen(true)}
      >
        <VerifiedIcon />
        Select Fitment
      </button>
    ) : null;

  const renderMobileFilterButtons = () => {
    return (
      <div
        className={classnames(styles.mobileFilterButtonsContainer, {
          [styles.singleColumn]: selectedFitmentStr || !groupId,
        })}
      >
        <button
          className={classnames(
            styles.facetButton,
            'Pl-filter-results--button'
          )}
          onClick={() => showFacetModal(true)}
        >
          <FunnelIcon />
          Filter Results
          {renderCounter()}
        </button>
        {groupId ? renderFitmentButton() : null}
      </div>
    );
  };

  const renderPageLoadingIndicator = () =>
    !productData && searchParams.q ? (
      <div className={styles.pageLoadingIndicator}>
        <LoadingIndicator type="linear" />
      </div>
    ) : null;

  const hasResults = productData?.list.length;

  return (
    <div
      className={classnames(
        styles.root,
        { disableAll: disbaleAllHtmlEl },
        'Pl-SearchPage--container'
      )}
    >
      {selectedFitmentStr && groupId ? (
        <Alert
          className={styles.fitmentVerifier}
          buttonText="Change fitment"
          onClick={() => setIsFitmentModalOpen(true)}
          styled
          showIcon={false}
          text={selectedFitmentStr}
          title={null}
          type="success"
        />
      ) : null}

      <BreadCrumb
        styled
        filters={
          searchParams.q
            ? [
                {
                  color: '#f8f8f8',
                  id: 1,
                  label: `keyword: ${searchParams.q}`,
                },
              ]
            : undefined
        }
        paths={{
          list: [
            {
              label: 'Home',
              url: '/',
            },
            {
              label: 'Search',
            },
          ],
          separator: '/',
        }}
        onRemoveFilter={() => {
          const params = new URLSearchParams(window.location.search);
          params.delete('q');
          window.location.href = `${window.location.origin}${
            window.location.pathname
          }?${params.toString()}`;
        }}
      />
      {renderPageLoadingIndicator()}
      <div
        className={classnames(styles.dataContainer, {
          [styles.noResults]: !hasResults,
        })}
      >
        {productData && productData?.facets.length ? (
          <>
            {renderMobileFilterButtons()}
            {renderFacet()}
          </>
        ) : (
          // We need this empty div to keep the layout splitted into 2 sections with css grid
          <div />
        )}

        <div>
          <WsmProductListWrapper
            className={styles.productList}
            onDataReceived={(data) => {
              setClearFacetsOnSave(data.clearFacetsOnFitmentSave);
              setDisableAllHtmlEl(false);
              setProductData(data);
              updateFacets(data);
            }}
            onPageChanged={(page) => {
              setSearchParams({ ...searchParams, page });
              setTimeout(() => {
                window.scrollTo({ behavior: 'smooth', top: 0 });
              }, 1);
              updateUrl<keyof SearchParams>('page', page);
            }}
            currentPage={searchParams.page}
            search={searchParams.q || ''}
            styled
            showControls={!!hasResults}
            renderNoResults={() => (
              <h4
                className={classnames(
                  styles.noResultMsg,
                  'Pl-SearchPage--no-results-msg'
                )}
              >
                No results for your current search. Try a new search or change
                your saved fitment.
              </h4>
            )}
            showPagination
            showLoadingIndicator={!!productData}
            query={productsQuery}
            itemsPerPage={searchParams.limit}
            onItemsPerPageChange={(value) => {
              setSearchParams({ ...searchParams, limit: value });
              updateUrl<keyof SearchParams>('limit', value);
            }}
            selectedSort={searchParams.sort}
            onSortChange={(value) => {
              setSearchParams({ ...searchParams, sort: value });
              updateUrl<keyof SearchParams>('sort', value);
            }}
            orientation={searchParams.orientation}
            onLayoutChange={(value) => {
              setSearchParams({ ...searchParams, orientation: value });
              updateUrl<keyof SearchParams>('orientation', value);
            }}
          />
        </div>
      </div>
      <FacetFilterModal
        classes={{ root: styles.modal }}
        isModalOpen={isFacetModalOpen}
        disbaleAllHtmlEl={disbaleAllHtmlEl}
        showModal={showFacetModal}
        renderCounter={renderCounter}
        renderFacet={renderFacet}
        setSelectedFacets={setSelectedFacets}
        totalResults={productData?.total || 0}
        facetKeys={facetKeys}
      />
      <FitmentSelectorModal
        classes={{ root: styles.modal }}
        isModalOpen={isFitmentModalOpen}
        showModal={setIsFitmentModalOpen}
        disbaleAllHtmlEl={disbaleAllHtmlEl}
        onSubmit={(data, changed) => {
          setSelectedFitmentStr(
            data.reduce((acc, item) => `${acc} ${item.value}`, '')
          );
          // When fitment changes the value we disable HTML elements to prevent clicks
          // and then onDataReceived callback in WsmProductListWrapper component will enable
          // again all elements setting the value disableAllHtmlEl to FALSE.
          // We don't want to disbale HTML elements if there is no change in the fitment.
          setDisableAllHtmlEl(changed);
          setSearchParams({ ...searchParams, page: 1 });
          const fitmentQuery = data.map((item) => item.value).join('|');
          setSelectedFitmentQuery(fitmentQuery);
          updateUrl<keyof SearchParams>('fitment', fitmentQuery);
        }}
        groupId={groupId}
        selectedFitment={selectedFitmentQuery}
      />
    </div>
  );
};

const SearchPage = ({ groupId }: SearchPageProps) => {
  return (
    <SearchPageProvider>
      <InnerSearchPage groupId={groupId} />
    </SearchPageProvider>
  );
};

export default SearchPage;
