/* eslint-disable complexity */
import classnames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import useEventCallback from '../../common/customHooks/useEventCallback';
import { env } from '../../config';
import { Error } from '../../models/generic';
import { FITMENT_SELECTOR_STORAGE_KEY } from '../../utils/constants';
import {
  convertSelectedDataToArray,
  validateSelectedValues,
} from '../../utils/fitmentUtils';
import restFactory from '../../utils/restFactory';
import Alert from '../Alert';
import {
  FitmentSelectorProps,
  FitmentLabelEntity,
  SelectedValues,
} from '../FitmentSelector/models';
import FitmentSelectorWrapper from '../FitmentSelectorWrapper';
import { getSelectedFitment } from '../WsmFitmentSelectorWrapper/WsmFitmentSelectorWrapper';
import { FitmentSelectorVerifierProps } from './models';
import styles from './styles/fitmentVerifier.scss';

interface VerifyFitmentResponse {
  valid: boolean;
}
interface AlertType {
  type: '' | 'success' | 'error';
  visible: boolean;
}

const FitmentSelectorVerifier = ({
  selectedValues,
  showMoreText,
  onShowMoreBtnClick,
  title,
  alertSuccessTitle = 'FITMENT VERIFIED FOR:',
  alertErrorTitle = "DOESN'T FIT:",
  alertButtonText = 'Change vehicle',
  onDoesntFit,
  doesntFitButtonText,
  alertFill,
  showAlertIcon,
  productId,
  onDataLoaded,
  ...fitmentWrapperProps
}: FitmentSelectorVerifierProps) => {
  const isMounted = useRef(true);
  const [alert, setAlert] = useState<AlertType>({ type: '', visible: false });
  const [isFitmentDataLoaded, setIsFitmentDataLoaded] = useState(false);
  const [
    selectedFitmentData,
    setSelectedFitmentData,
  ] = useState<SelectedValues>(selectedValues || {});
  const [fitmentError, setFitmentError] = useState<Error>();
  const [fitmentKey, setFitmentKey] = useState<number>(0);
  const labels = useRef([]);
  const labelsData = useRef({});

  useEffect(() => () => (isMounted.current = false), []);

  const getSelectedFitmentFromLocalStorage = (labels) => {
    const savedFitment =
      JSON.parse(
        localStorage.getItem(FITMENT_SELECTOR_STORAGE_KEY) || '{}'
      )?.[0] || '';

    const selectedFitmentObj = getSelectedFitment(labels, savedFitment) || {};

    return selectedFitmentObj;
  };

  useEffect(() => {
    if (selectedFitmentData !== selectedValues) {
      setSelectedFitmentData(selectedValues);
      if (alert.visible) {
        const validLabels = validateSelectedValues(
          selectedValues,
          labelsData.current,
          labels.current
        );
        const areLabelsValid = areLabelValuesValid(labels.current, validLabels);
        setAlert({ type: areLabelsValid ? 'success' : 'error', visible: true });
      }
    }
  }, [selectedValues]);

  const onFitmentDataLoaded = useEventCallback(
    async (
      _labels: FitmentLabelEntity[],
      _optionalLabels: FitmentLabelEntity[],
      _labelsData: FitmentSelectorProps['labelsData']
    ) => {
      onDataLoaded?.(_labels, _optionalLabels, _labelsData);
      if (!isMounted.current) return;
      setFitmentError(null);

      labels.current = [..._labels, ..._optionalLabels];
      labelsData.current = _labelsData;

      let currentSelectedFitment = selectedFitmentData || {};
      if (
        !Object.keys(selectedFitmentData || {}).length &&
        labels.current.length
      ) {
        currentSelectedFitment = getSelectedFitmentFromLocalStorage(
          labels.current
        );
        setSelectedFitmentData(currentSelectedFitment);
        if (Object.keys(currentSelectedFitment || {}).length) {
          setFitmentKey(fitmentKey + 1);
        } else {
          setIsFitmentDataLoaded(true);
        }
      }
      if (!Object.keys(currentSelectedFitment).length) {
        setIsFitmentDataLoaded(true);
      }

      if (
        [..._labels, ..._optionalLabels].length ===
          Object.keys(currentSelectedFitment || {}).length &&
        Object.keys(_labelsData).length === _labels.length
      ) {
        const selectedLabels = Object.keys(
          validateSelectedValues(currentSelectedFitment, _labelsData, _labels)
        ).length;
        if (!Object.keys(currentSelectedFitment).length && !selectedLabels) {
          setAlert({ type: '', visible: false });
        } else if (Object.keys(labels.current).length > selectedLabels) {
          setAlert({ type: 'error', visible: true });
        } else if (Object.keys(labels.current).length === selectedLabels) {
          if (productId) {
            verifyFitmentWithProduct(
              _labels,
              _labelsData,
              currentSelectedFitment
            );
          } else {
            setAlert({ type: 'success', visible: true });
          }
        }
      } else if (
        // This conditional only happens when user hasn't changed the initial selection (first render)
        // and there is not enough data for the fitment
        selectedValues === currentSelectedFitment &&
        Object.keys(selectedValues || {}).length &&
        [..._labels, ..._optionalLabels].length !==
          Object.keys(currentSelectedFitment).length
      ) {
        setAlert({ type: 'error', visible: true });
      }
    },
    [selectedFitmentData, productId]
  );

  const verifyFitmentWithProduct = async (labels, labelsData, selectedData) => {
    const fitmentStr = convertSelectedDataToArray(
      labels,
      selectedData,
      labelsData
    );
    const response = await restFactory.get<VerifyFitmentResponse>(
      `${env.API_URL}/fitment/check`,
      {
        fitment: fitmentStr.map((item) => item.value).join('|'),
        productId,
      }
    );

    setAlert({
      type: response.valid ? 'success' : 'error',
      visible: true,
    });
    return;
  };

  const onSubmit = (values) => {
    const areAllLabelsSelected = areLabelValuesValid(labels.current, values);
    if (productId) {
      verifyFitmentWithProduct(
        labels.current,
        labelsData.current,
        selectedFitmentData
      );
    } else if (!fitmentWrapperProps.autocommit || areAllLabelsSelected) {
      setAlert({
        type: areAllLabelsSelected ? 'success' : 'error',
        visible: true,
      });
    }
    fitmentWrapperProps?.onSubmit?.(values);
  };

  const alertClick = () => {
    setAlert({ type: '', visible: false });
    setIsFitmentDataLoaded(true);
  };

  return (
    <div
      className={classnames(styles.root, 'Sui-FitmentSelectorVerifier--root')}
    >
      <Alert
        className={styles.alert}
        type={alert.type === 'error' ? 'error' : 'success'}
        title={alert.type === 'error' ? alertErrorTitle : alertSuccessTitle}
        text={fitmentToString(
          labels.current,
          selectedFitmentData,
          labelsData.current
        )}
        onClick={alertClick}
        buttonText={alertButtonText}
        onSecondaryButtonClick={onDoesntFit || null}
        secondaryButtonText={doesntFitButtonText || null}
        fill={alertFill}
        showIcon={showAlertIcon}
        styled={fitmentWrapperProps.styled}
        style={{ display: !alert.visible ? 'none' : '' }}
      />
      <div
        data-testid="fitment-verifier-selectors"
        style={{ display: alert.visible || !isFitmentDataLoaded ? 'none' : '' }}
      >
        {title && (
          <p
            className={classnames(
              styles.fitmentTitle,
              'Sui-FitmentSelectorVerifier--title'
            )}
          >
            {title}
          </p>
        )}
        <div className={styles.fitment}>
          <FitmentSelectorWrapper
            key={fitmentKey}
            {...fitmentWrapperProps}
            onSubmit={onSubmit}
            onChange={(_, values) => {
              setSelectedFitmentData(values || {});
              fitmentWrapperProps?.onChange?.(_, values);
            }}
            onDataLoaded={onFitmentDataLoaded}
            onError={(err) => {
              setIsFitmentDataLoaded(true);
              setFitmentError(err);
            }}
            selectedValues={selectedFitmentData}
          />
          {showMoreText && onShowMoreBtnClick && !fitmentError ? (
            <button
              className={styles.searchVehicleBtn}
              onClick={onShowMoreBtnClick}
            >
              {showMoreText}
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

function fitmentToString(
  labels: FitmentLabelEntity[],
  selectedFitmentData: SelectedValues,
  labelsData: FitmentSelectorProps['labelsData']
) {
  let text = '';
  if (
    labels.length &&
    Object.keys(selectedFitmentData || {}).length &&
    Object.keys(labelsData).length
  ) {
    for (const item of labels) {
      const labelValue = selectedFitmentData[item.name];
      if (labelValue) {
        const labeldDataMatch = (labelsData[item.name] || []).find(
          (item) =>
            String(item.id).toLowerCase() === String(labelValue).toLowerCase()
        );
        if (!labeldDataMatch) {
          text =
            'There is an error on your Fitment selection, please try again.';
          break;
        }
        text += ` ${labeldDataMatch.name}`;
      }
    }
  }
  return text.trim();
}

function areLabelValuesValid(
  labels: FitmentLabelEntity[],
  selectedValues: SelectedValues
) {
  return (
    Object.keys(labels).length ===
    Object.values(selectedValues).filter((value) => value).length
  );
}

export default FitmentSelectorVerifier;

export function getFitmentQueryStr(fitment: string) {
  const regex = / /g;
  return fitment.replace(regex, '|');
}
